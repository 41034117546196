import SSImage from '@/src/component/superstack/base/SSImage';
import { CDNLocal, CDNProd, isProduction } from '@/src/utils/api';

const SS_STACK_FILE_NAMES = [
  '_net',
  'android',
  'angular',
  'ansible',
  'apache_server',
  'airflow',
  'kafka',
  'solr',
  'arduino',
  'asp_net',
  'aws',
  'aws_cloudwatch',
  'aws_ec2',
  'aws_lambda',
  'aws_ml_cloud',
  'aws_s3',
  'azure',
  'backbone_js',
  'big_data',
  'ble',
  'blockchain',
  'bluetooth',
  'bootstrap',
  'c',
  'c%23',
  'c%2B%2B',
  'cassandra',
  'chatbot',
  'chrome_extensions',
  'clojure',
  'cloud_system',
  'codeigniter',
  'computer_vision',
  'cordova',
  'css',
  'd3_js',
  'dart',
  'devexpress',
  'django',
  'docker',
  'drupal',
  'egovframe',
  'elasticsearch',
  'electron',
  'elixir',
  'embedded',
  'ember_js',
  'erlang',
  'express',
  'firebase',
  'flask',
  'flutter',
  'gatsby',
  'git',
  'golang',
  'graphql',
  'groovy',
  'hadoop',
  'haskell',
  'html',
  'ibatis',
  'ionic',
  'ios',
  'iot',
  'java',
  'javascript',
  'jenkins',
  'jpa',
  'jquery',
  'jsp',
  'kafka',
  'kibana',
  'kotlin',
  'kubernetes',
  'laravel',
  'linux',
  'logstash',
  'macos',
  'magento',
  'mariadb',
  'msa',
  'mongodb',
  'mssql',
  'mybatis',
  'mysql',
  'nlp',
  'next_js',
  'nginx',
  'node_js',
  'nosql',
  'objective_c',
  'opencv',
  'oracle',
  'perl',
  'php',
  'postgresql',
  'python',
  'r',
  'react',
  'react_native',
  'react_js',
  'redis',
  'redux',
  'ruby',
  'ruby_on_rails',
  'rust',
  'rxjs',
  'salesforce',
  'sap',
  'scala',
  'scss',
  'selenium',
  'shopify',
  'socket_io',
  'solidity',
  'spark',
  'spring',
  'spring_boot',
  'spring_framework',
  'sql',
  'sqlite',
  'stripe',
  'swift',
  'symfony',
  'tailwind_css',
  'tensorflow',
  'terraform',
  'three_js',
  'tomcat',
  'typescript',
  'unity',
  'unix',
  'visual_basic',
  'vue_js',
  'webrtc',
  'websocket',
  'windows',
  'winform',
  'woocommerce',
  'wordpress',
  'wpf',
  'xamarin',
  //  221213
  'reactorkit',
  'presto',
  'aws_athena',
  'jasmine',
  'cypress',
  'alamofire',
  'metabase',
  'drupal',
  'vault',
  'gitlab',
  'hbase',
  'zabbix',
  'data_studio',
  'bitbucket',
  'rancher',
  'aws_codedeploy',
  'circle_ci',
  'celery',
  'istio',
  'flink',
  'grpc',
  'retrofit',
  'helm',
  'mockito',
  'kubeflow',
  'sentry',
  'react_query',
  'dagger',
  'aws_kinesis',
  'github_action',
  'karma',
  'thymeleaf',
  'heroku',
  'prometheus',
  'rest_api',
  'tableau',
  'mlflow',
  'envoy',
  'recoil',
  'exoplayer',
  'zendesk',
  'appium',
  'sanic',
  'influxdb',
  'travis_ci',
  'jira',
  'fluentd',
  'puppeteer',
  'aws_redshift',
  'emotion',
  'nuxtjs',
  'aws_codepipeline',
  'aws_dynamodb',
  'harbor',
  'bitrise',
  'fastapi',
  'r',
  'zustand',
  'playwright',
  'svelte',
  'aws_sqs',
  'junit',
  'redash',
  'jest',
  'snapkit',
  'aws_ses',
  'jotai',
  'vercel',
  'spinnaker',
  'fastlane',
  'traefik',
  'jaeger',
  'redux',
  'mocha',
  'cucumber',
  'ncp',
  'styled_components',
  'nestjs',
  'sinon',
  'couchbase',
  'memcached',
  'aws_auroradb',
  'storybook',
  'superset',
  'zipkin',
  'aws_rds',
  'bigquery',
  'go_cd',
  'swagger_ui',
  'aws_codebuild',
  'mobx',
  'zeppelin',
  'computer_vision',
  'aws_sns',
  'sonarqube',
  'realm',
  'testing_library',
  'enzyme',
  'moya',
  'argo_cd',
  'koa',
  'hive',
  'ribs',
  'bazel',
  'grafana',
  'neo4j',
  'druid',
  'packer',
];

const StackImage = ({ flag, alt, width, height, ...props }) => {
  const domainCDN = isProduction ? CDNProd : CDNLocal;
  const filename = encodeURIComponent(flag.toLowerCase().replace(/\./gi, '_'));
  const isValidFileName = (filename) => {
    return SS_STACK_FILE_NAMES.includes(filename);
  };

  return (
    <SSImage
      src={
        isValidFileName(filename)
          ? `${domainCDN}/superstack/stacks/${filename}.svg`
          : `${domainCDN}/superstack/stacks/default.svg`
      }
      alt={alt || 'Stack Img'}
      width={width}
      height={height}
      layout={'fixed'}
      {...props}
    />
  );
};

export default StackImage;
