import classes from '@/styles/superstack/base/Button.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(classes);

import IconArrowLeft from '@/asset/superstack/svgs/icon-arrow-left.svg';
import IconArrowRight from '@/asset/superstack/svgs/icon-arrow-right.svg';

const CarouselArrow = ({ clickHandler, showing, direction, size = 'lg', ...props }) => {
  const iconSize = size === 'sm' ? 12 : 16;
  const IconArrow = direction === 'prev' ? IconArrowLeft : IconArrowRight;
  return (
    <span
      className={cx(['carousel-arrow', direction, showing ? '' : 'hide', size])}
      onClick={clickHandler}
      {...props}
    >
      <IconArrow width={iconSize} height={iconSize} fill={'#000000'} />
    </span>
  );
};

const CarouselArrowPrev = ({ clickHandler, hasPrev, size = 'lg', ...props }) => {
  return (
    <CarouselArrow
      clickHandler={clickHandler}
      showing={hasPrev}
      direction={'prev'}
      size={size}
      {...props}
    />
  );
};

const CarouselArrowNext = ({ clickHandler, hasNext, size = 'lg', ...props }) => {
  return (
    <CarouselArrow
      clickHandler={clickHandler}
      showing={hasNext}
      direction={'next'}
      size={size}
      {...props}
    />
  );
};

export { CarouselArrowPrev, CarouselArrowNext };
