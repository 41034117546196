import { useState } from 'react';
import classNames from 'classnames/bind';
import classes from '@/styles/superstack/base/Stack.module.scss';
const cx = classNames.bind(classes);
import AngleLeft from '@/asset/superstack/svgs/angle-left.svg';
import AngleRight from '@/asset/superstack/svgs/angle-right.svg';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { mobileState } from '@/src/utils/hooks/useMobileSize';
import useUserStack from '@/src/utils/hooks/superstack/useUserStack';
import StackImage from '@/src/component/superstack/stacks/StackImage';
import { normalizeStackName } from '@/src/utils/ss_utils';
import { Chip } from '@/src/component/superstack/base/Chip';
import IconStars from '@/asset/superstack/svgs/icon-stars.svg';
import {
  CarouselArrowNext,
  CarouselArrowPrev,
} from '@/src/component/superstack/base/button/CarouselArrow';

export const StackCard = ({ userStacks, jobStack, size = 'lg' }) => {
  const userStacks_ = Object.fromEntries(
    Object.entries(userStacks).map(([name, termMonth]) => {
      return [normalizeStackName(name), termMonth];
    }),
  );
  const stack_name = jobStack.stack.name;
  const stack_flag = jobStack.stack.flag;
  const [isSatisfied, setIsSatisfied] = useState(false);
  const hasUserStack = () => {
    return Object.keys(userStacks_).includes(stack_flag);
  };
  const overRequiredYear = () => {
    if (!jobStack.required_year) {
      return true;
    }
    return userStacks_?.[stack_flag] / 12 > jobStack.required_year;
  };
  useEffect(() => {
    setIsSatisfied(hasUserStack() && overRequiredYear());
  }, [userStacks_]);

  let imgWidth;
  let style;
  let textStyle;
  switch (size) {
    case 'xs':
      imgWidth = 16;
      style = {
        padding: '5px 8px',
        borderRadius: '12px',
      };
      textStyle = {};
      break;
    case 'sm':
      imgWidth = 32;
      style = {
        width: 84,
        padding: '16px 8px',
        borderRadius: '12px',
      };
      textStyle = {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      };
      break;
    case 'md':
      imgWidth = 40;
      style = {
        width: 107,
        padding: '24px 16px',
        borderRadius: '12px',
      };
      textStyle = {};
      break;
    default:
      imgWidth = 40;
      style = {
        width: 120,
        padding: '24px 16px',
        borderRadius: '12px',
      };
      textStyle = {};
  }

  const isSizeXS = size === 'xs';
  const requiredCareer =
    jobStack.required_year > 0 ? jobStack.required_year + '년' : isSizeXS ? '' : '경력 무관';
  return (
    <div className={cx(['stack-card'], isSatisfied ? 'satisfied' : '')} style={style}>
      <div className={classes[isSizeXS ? 'stack-card__row' : 'stack-card__column']}>
        <StackImage
          alt="스택 이미지"
          flag={jobStack.stack.flag}
          width={imgWidth}
          height={imgWidth}
          layout="fixed"
          priority={true}
        />
        {isSizeXS ? (
          <p className={classes['skill']} style={textStyle}>
            {stack_name} {requiredCareer ? `ㆍ${requiredCareer}` : ''}
          </p>
        ) : (
          <>
            <p className={classes['skill']} style={textStyle}>
              {stack_name}
            </p>
            <p className={classes['year']}>{requiredCareer}</p>
          </>
        )}
        {isSatisfied && (
          <Chip
            mode={'filled'}
            size={'sm'}
            clickable={false}
            style={{
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#FFFCF2',
            }}
          >
            <span style={{ width: '14px', height: '14px', marginRight: '6px' }}>
              <IconStars width={14} height={14} fill={'#FFCC00'} />
            </span>
            보유 기술
          </Chip>
        )}
      </div>
    </div>
  );
};

export const SimpleStackCard = ({ flag, name }) => {
  return (
    <div className={classes['simple-stack-card']}>
      <StackImage
        alt="스택 이미지"
        flag={flag}
        width={24}
        height={24}
        layout="fixed"
        priority={true}
      />
      <span className={classes['simple-stack-card__name']}>{name}</span>
    </div>
  );
};

export const SimpleCareerStackCard = ({ flag, name, career, bgColor = null }) => {
  return (
    <div className={classes['simple-career-stack-card']} style={{ backgroundColor: bgColor }}>
      <StackImage
        alt="스택 이미지"
        flag={flag}
        width={16}
        height={16}
        layout="fixed"
        priority={true}
      />
      <span className={classes['simple-career-stack-card__name']}>
        <span className={classes['simple-career-stack-card__skill']}>{name}</span>
        <span className={classes['simple-career-stack-card__year']}>
          {career && career !== '-' ? `ㆍ${career}` : ''}
        </span>
      </span>
    </div>
  );
};

export const RequiredStackBox = ({
  jobStacks,
  username,
  stackCardSize = 'lg',
  stackChuckSize = 5,
  onList = false,
}) => {
  if (!jobStacks) {
    jobStacks = [];
  }
  const requiredStacks = jobStacks.filter((jobStack) => jobStack.is_required);
  const { data: { data: { stacks = {} } = {} } = {} } = useUserStack(username);
  const isMobile = useRecoilValue(mobileState);
  const stackCardSize_ = isMobile ? 'md' : stackCardSize;

  const [stackPage, setStackPage] = useState(1);
  const cntRequiredStacks = requiredStacks.length;
  const requiredStacks_ = isMobile
    ? requiredStacks
    : requiredStacks.slice((stackPage - 1) * stackChuckSize, stackPage * stackChuckSize);
  const hasPrevPage = !isMobile && stackPage > 1;
  const hasNextPage = !isMobile && stackPage * stackChuckSize < cntRequiredStacks;

  useEffect(() => {
    setStackPage(1);
  }, [JSON.stringify(jobStacks)]);

  return (
    <div className={cx(['stack-box-wrapper', 'required-stack-box'])}>
      <div className={classes['stack-box']} style={{ position: 'relative' }}>
        <div className={classes['stack-box__title']}>
          <p>필수 기술 스택</p>
        </div>
        <div className={cx(['stack-container', isMobile ? '' : 'flex-hidden'])}>
          <CarouselArrowPrev
            size={'sm'}
            hasPrev={hasPrevPage}
            clickHandler={() => {
              setStackPage(stackPage - 1);
            }}
            style={{
              position: 'absolute',
              left: '-14px',
              top: '107px',
              padding: onList ? '0 6px' : '6px',
            }}
          />
          {requiredStacks_.map((jobStack) => {
            return (
              <StackCard
                size={stackCardSize_}
                userStacks={stacks}
                jobStack={jobStack}
                key={jobStack.stack.name}
              />
            );
          })}
          <CarouselArrowNext
            size={'sm'}
            hasNext={hasNextPage}
            clickHandler={() => {
              setStackPage(stackPage + 1);
            }}
            style={{
              position: 'absolute',
              right: '-14px',
              top: '107px',
              padding: onList ? '0 6px' : '6px',
            }}
          />
        </div>
      </div>
      {jobStacks.length !== requiredStacks.length && (
        <div className={classes['stack-box']}>
          <div className={classes['stack-box__title']}>
            <p>우대 기술 스택</p>
          </div>
          <div className={cx(['stack-container', isMobile ? '' : 'flex-wrap'])}>
            {jobStacks
              .filter((jobStack) => {
                return !jobStack.is_required;
              })
              .map((jobStack) => {
                return (
                  <SimpleStackCard
                    key={jobStack.stack.flag}
                    flag={jobStack.stack.flag}
                    name={jobStack.stack.name}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export const StackBundleBox = ({ jobStacks, chunkSize = 5 }) => {
  if (!jobStacks) {
    jobStacks = [];
  }
  const requiredStacks = jobStacks.filter((jobStack) => jobStack.is_required);
  const [page, setPage] = useState(0);
  const jobStackChunk = [].concat.apply(
    [],
    requiredStacks.map(function (elem, i) {
      return i % chunkSize ? [] : [requiredStacks.slice(i, i + chunkSize)];
    }),
  );
  const pageNeeded = requiredStacks.length > chunkSize;
  const firstPage = 0;
  const lastPage = jobStackChunk.length - 1;

  return (
    <div className={`${cx(['stack-box-wrapper', 'stack-bundle-wrapper'])} mt-20`}>
      {pageNeeded && (
        <div
          className={cx('next-page')}
          onClick={() => {
            setPage(Math.max(page - 1, firstPage));
          }}
        >
          {page > firstPage && <AngleLeft width={12} height={12} fill={'#9E9E9E'} />}
        </div>
      )}
      <div className={cx(['stack-bundle-box', pageNeeded ? '' : 'extra-margin'])}>
        {jobStackChunk[page]?.map((stack) => {
          const name = stack.stack.name;
          return (
            <div className={cx('stack-bundle-box__item')} key={name}>
              <StackImage
                alt="스택 이미지"
                flag={stack.stack.flag}
                width={24}
                height={24}
                layout="fixed"
                priority={true}
              />
              <span>{[`${name}${stack.required_year ? `ㆍ${stack.required_year}년` : ''}`]}</span>
            </div>
          );
        })}
      </div>
      {pageNeeded && (
        <div
          className={cx('next-page')}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setPage(Math.min(page + 1, lastPage));
          }}
        >
          {page < lastPage && <AngleRight width={12} height={12} fill={'#9E9E9E'} />}
        </div>
      )}
    </div>
  );
};
