import classNames from 'classnames/bind';
import styles from '@/styles/superstack/base/Chip.module.scss';
import IconClose from '@/asset/superstack/svgs/icon-close.svg';
import PropTypes from 'prop-types';

const Chip = ({ mode, size, clickable = true, ...props }) => {
  const cx = classNames.bind(styles);
  const cns = [
    `chip--${mode}`,
    `chip--${size}`,
    props.withClose ? 'chip-close' : '',
    clickable ? '' : 'unclickable',
  ];
  return (
    <button className={cx(cns)} {...props}>
      {props.children}
    </button>
  );
};

const ChipClose = ({ mode, size, widthClose = 8, heightClose = 8, ...props }) => {
  const cx = classNames.bind(styles);
  return (
    <Chip mode={mode} size={size} withClose={true} {...props}>
      <div className={cx('content-box')}>
        <div className={cx('content-with-icon')}>{props.children}</div>
        <IconClose width={widthClose} height={heightClose} />
      </div>
    </Chip>
  );
};

Chip.propTypes = {
  /**
   * What mode
   */
  mode: PropTypes.oneOf(['default', 'filled', 'text', 'outline']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Chip.defaultProps = {
  mode: 'filled',
  size: 'lg',
  children: '칩칩',
};

ChipClose.propTypes = {
  /**
   * What mode
   */
  mode: PropTypes.oneOf(['default', 'filled', 'text']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  withClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ChipClose.defaultProps = {
  mode: 'filled',
  size: 'lg',
  withClose: true,
  children: '칩칩',
};

export { Chip, ChipClose };
