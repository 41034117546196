import useSWR from 'swr';
import { api } from '@/src/utils/api';
import { ApiUrls } from '@/data/superstack/ApiUrls';

export default function useUserStack(username, refreshInterval = null, jobId = null) {
  const fetcher = async (...args) =>
    api.get(...args).then((res) => {
      switch (res.status) {
        case 200:
          return res;
        case 404:
          throw new Error('No Users Found');
        default:
          return res;
      }
    });
  const apiUrl = ApiUrls(null, null, username).find((url) => url.name === 'ss_profile_summary').url;
  const { data, error, mutate } = useSWR(
    username ? (jobId ? apiUrl + `?jobId=${jobId}` : apiUrl) : null,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnMount: true,
      refreshInterval: refreshInterval,
    },
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
